<template>
  <div class="asf-tab">
    <AsfButton
      role="tab"
      :class="[`asf-tab__button--${type}`, { 'm-active': isActive }]"
      :aria-controls="`tab-content-${id}`"
      :aria-selected="isActive.toString()"
      @click="onTabClick"
    >
      <div v-if="isShipping(type)">
        <AsfIcon :name="icon" size="16" class="asf-tab__icon" />
        <span class="asf-tab__shipping-title">{{ title }}</span>
      </div>
      <span v-else>{{ title }}</span>
    </AsfButton>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { AsfIconProps, AsfTabProps } from '@ui/types'

export default defineComponent({
  name: 'AsfTab',
  props: {
    /**
     * Defines variant of the tabs
     */
    type: { type: String as PropType<AsfTabProps['type']>, default: 'original' },
    /**
     * Defines id of the current tab
     */
    id: { type: String as PropType<AsfTabProps['id']>, default: '' },
    /**
     * Initial active tab
     */
    activeTab: { type: String as PropType<AsfTabProps['activeTab']>, required: true },
    /**
     * Defines title for the tab
     */
    title: { type: String as PropType<AsfTabProps['title']>, required: true },
    /**
     * Defines name for the icon in shipping story
     */
    iconName: { type: String as PropType<AsfTabProps['iconName']>, default: '' }
  },
  setup(props, context) {
    const onTabClick = () => {
      context.emit('toggle', props.id)
    }

    const icon: AsfIconProps['name'] = props.iconName === 'check' ? 'order-check' : 'delivery'

    const isActive = computed(() => props.activeTab === props.id)

    const isShipping = (type: AsfTabProps['type']) => type === 'shipping'

    return {
      onTabClick,
      isActive,
      icon,

      isShipping
    }
  }
})
</script>
